import styled from "styled-components";
import { stylesGenerator as responsiveStylesGenerator } from "@madebywild/styled-utils";
import { stylesGenerator } from "./Grid.generator";
import { Item } from "./Item";

type CompoundGrid = typeof StyledGrid & {
  Item: typeof Item;
};

const generator = responsiveStylesGenerator(stylesGenerator);

const StyledGrid = styled.div(generator);

const Grid = StyledGrid as CompoundGrid;

Grid.Item = Item;

export { Grid };
