import type React from "react";
import type { CSSObject, DefaultTheme, ThemedStyledProps } from "styled-components";
import type { ItemProps } from "./Item.types";

const lineGenerator = (value: React.ReactText | React.ReactText[]) => {
  if (Array.isArray(value)) {
    return value.join(" / ");
  }

  return value;
};

const stylesGenerator = ({ area, row, column, alignSelf }: ThemedStyledProps<ItemProps, DefaultTheme>) => {
  const style: CSSObject = {
    gridArea: area,
  };

  /** Column */
  if (typeof column !== "undefined") {
    style.gridColumn = lineGenerator(column);
  }

  /** Row */
  if (typeof row !== "undefined") {
    style.gridRow = lineGenerator(row);
  }

  /** Align items */
  if (["start", "end", "stretch", "baseline"].includes(alignSelf!)) {
    style.alignSelf = alignSelf;
  }

  return style;
};

export { lineGenerator, stylesGenerator };
