import { useEffect } from "react";

type Options = {
  /** Whether to disable the listener.  */
  isDisabled?: boolean;
  /** The side effect to trigger when the user presses the specified key. */
  effect?: () => void;
};

/**
 * TODO: Extract this to a separate package.
 *
 * Triggers a side effect when the user presses the specified key.
 *
 * @param targetKey             The key to listen for. Example: 'Enter'.
 * @param params.isDisabled     Whether to disable the listener.
 * @param params.effect         The side effect to trigger when the user presses the specified key.
 */
export function useKeyPressListener(targetKey: string, params: Options) {
  const { isDisabled, effect } = params;

  useEffect(() => {
    if (isDisabled) return;

    const downHandler = ({ key }: KeyboardEvent) => {
      if (key === targetKey) {
        effect?.();
      }
    };

    window.addEventListener("keydown", downHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, [targetKey, isDisabled, effect]);
}
