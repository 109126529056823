module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[[null,{"type":"yaml","marker":"-"}],null],"rehypePlugins":[null,null],"gatsbyRemarkPlugins":[],"defaultLayouts":{"default":"/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js"},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/forge/docs.wild.plus/.docz"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-theme-docz/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{},"src":"./","gatsbyRoot":null,"themesDir":"src","mdxExtensions":[".md",".mdx"],"docgenConfig":{},"menu":["Welcome",{"name":"Boilerplates","menu":["Getting Started"]},{"name":"Code Linting","menu":["Getting Started"]},{"name":"Github Actions","menu":["Getting Started"]},"Private Registry","Styled","Local Setup",{"name":"Server Setup","menu":["Getting Started"]},"Code Challenge"],"mdPlugins":[],"hastPlugins":[],"ignore":[],"typescript":true,"ts":false,"propsParser":true,"props-parser":true,"debug":false,"native":false,"openBrowser":null,"o":null,"open":null,"open-browser":null,"root":"/home/forge/docs.wild.plus/.docz","base":"/","source":"./","gatsby-root":null,"files":["**/*/*.mdx","**/.*/*.mdx"],"public":"/public","dest":".docz/dist","d":".docz/dist","editBranch":"master","eb":"master","edit-branch":"master","config":"","title":"for developers","description":"wild developers guidelines and documentation","host":"localhost","port":3000,"p":3000,"separator":"-","paths":{"root":"/home/forge/docs.wild.plus","templates":"/home/forge/docs.wild.plus/node_modules/docz-core/dist/templates","docz":"/home/forge/docs.wild.plus/.docz","cache":"/home/forge/docs.wild.plus/.docz/.cache","app":"/home/forge/docs.wild.plus/.docz/app","appPackageJson":"/home/forge/docs.wild.plus/package.json","appTsConfig":"/home/forge/docs.wild.plus/tsconfig.json","gatsbyConfig":"/home/forge/docs.wild.plus/gatsby-config.js","gatsbyBrowser":"/home/forge/docs.wild.plus/gatsby-browser.js","gatsbyNode":"/home/forge/docs.wild.plus/gatsby-node.js","gatsbySSR":"/home/forge/docs.wild.plus/gatsby-ssr.js","importsJs":"/home/forge/docs.wild.plus/.docz/app/imports.js","rootJs":"/home/forge/docs.wild.plus/.docz/app/root.jsx","indexJs":"/home/forge/docs.wild.plus/.docz/app/index.jsx","indexHtml":"/home/forge/docs.wild.plus/.docz/app/index.html","db":"/home/forge/docs.wild.plus/.docz/app/db.json"}},
    }]
