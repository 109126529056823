import { Playground, Props } from "docz";
import { Code } from "gatsby-theme-docz/src/components/Code";
import { Modal } from "../../../../../submodules/styled/packages/modal/Modal.tsx";
import { PropsTable } from "../../../../../submodules/styled/packages/modal/Modal.props.tsx";
import { ModalDialog, StoryScene, ModalBackdrop } from "../../../../../submodules/styled/packages/modal/stories/common";
import * as React from 'react';
export default {
  Playground,
  Props,
  Code,
  Modal,
  PropsTable,
  ModalDialog,
  StoryScene,
  ModalBackdrop,
  React
};