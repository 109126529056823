export type Screens = "sm" | "md" | "lg" | "xl" | "2xl";

export interface Theme {
  screens: Record<Screens, number | string>;
}

export const theme: Theme = {
  screens: {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    "2xl": 1600,
  },
};
