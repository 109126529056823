import { Playground, Props } from "docz";
import { Code } from "gatsby-theme-docz/src/components/Code";
import { Grid } from "../../../../../submodules/styled/packages/grid/Grid";
import { PropsTable } from "../../../../../submodules/styled/packages/grid/Grid.props";
import * as React from 'react';
export default {
  Playground,
  Props,
  Code,
  Grid,
  PropsTable,
  React
};