import type React from "react";
import type { CSSObject, DefaultTheme, ThemedStyledProps } from "styled-components";
import type { GridProps } from "./Grid.types";

const normalizeUnit = (value: React.ReactText, unit = "fr") => {
  return typeof value === "string" || isNaN(value) ? value : `${value}${unit}`;
};

const sizingGenerator = (value?: React.ReactText | React.ReactText[]) => {
  if (typeof value === "number") {
    return `repeat(${value}, 1fr)`;
  }

  if (Array.isArray(value)) {
    return value.map((value) => normalizeUnit(value)).join(" ");
  }

  return value;
};

const stylesGenerator = ({
  rows,
  columns,
  alignItems,
  justify,
  gap,
  areas,
}: ThemedStyledProps<GridProps, DefaultTheme>) => {
  const style: CSSObject = {
    display: "grid",
    gridTemplateColumns: sizingGenerator(columns),
    gridTemplateRows: sizingGenerator(rows),
  };

  /* Gap */
  if (typeof gap === "number") {
    style.gap = gap;
  } else if (Array.isArray(gap)) {
    style.gap = gap.map((value) => normalizeUnit(value, "px")).join(" ");
  }

  /** Align items */
  if (["start", "end"].includes(alignItems!)) {
    style.alignItems = `flex-${alignItems}`;
  } else if (["center", "stretch", "baseline"].includes(alignItems!)) {
    style.alignItems = alignItems;
  }

  /** Justify content */
  if (["start", "end"].includes(justify!)) {
    style.justifyContent = `flex-${justify}`;
  } else if (["between", "around", "evenly"].includes(justify!)) {
    style.justifyContent = `space-${justify}`;
  } else if (justify === "center") {
    style.justifyContent = justify;
  }

  /** Areas */
  if (Array.isArray(areas)) {
    style.gridTemplateAreas = `${areas.map((value) => `'${value}'`).join(" ")}`;
  }

  return style;
};

export { sizingGenerator, stylesGenerator };
