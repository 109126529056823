import styled from "styled-components";

/**
 * Simple styled component that makes sure the modal
 * can be placed inside the whole viewport.
 *
 * Uses 'isolation' to define its own context for z-index.
 */
export const FullscreenContainer = styled.div`
  isolation: isolate;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
`;

/**
 * Container to center the actual modal content.
 */
export const DefaultContentContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
`;

/**
 * Layout-component to align the backdrop.
 * Doesn't apply any visual styles, this is
 * done by the actual backdrop element.
 */
export const BackdropContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
`;

/**
 * Visible part of the backdrop. For layout,
 * see the 'BackdropContainer' component.
 */
export const DefaultBackdropContent = styled.div`
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 50%;
`;
