import { Link, Playground } from "docz";
import { ThemeProvider } from "styled-components";
import { Grid } from "../../../../../submodules/styled/packages/grid/Grid";
import { theme } from "../../../../../submodules/styled/packages/utils/theme";
import * as React from 'react';
export default {
  Link,
  Playground,
  ThemeProvider,
  Grid,
  theme,
  React
};