import FocusTrap from "focus-trap-react";
import React, { forwardRef, PropsWithChildren, useRef } from "react";
import { createPortal } from "react-dom";
import { useBodyScrollLockEffect } from "./hooks/useBodyScrollLockEffect";
import { useClickAwayListener } from "./hooks/useClickAwayListener";
import { useIsMounted } from "./hooks/useIsMounted";
import { useKeyPressListener } from "./hooks/useKeyPressListener";
import { BackdropContainer, DefaultContentContainer, DefaultBackdropContent, FullscreenContainer } from "./styles";
import { ModalProps } from "./types";

/**
 * A modal component that can be used to display content in a modal window.
 * Styling is done by consumer of this component, as its task is to properly
 * align the children on the screen and handle modal-related user interactions.
 */
export const Modal = forwardRef<HTMLDivElement, PropsWithChildren<ModalProps>>((props, ref) => {
  const {
    children,
    isOpen,
    isClickAwayDisabled,
    isEscapeKeypressDisabled,
    isBodyScrollLockDisabled,
    backdrop = <DefaultBackdropContent />,
    ContentContainer = DefaultContentContainer,
    onClose,
  } = props;

  const contentContainerRef = useRef<HTMLDivElement>(null);
  const isMounted = useIsMounted();

  useBodyScrollLockEffect({ isDisabled: !isOpen || isBodyScrollLockDisabled });
  useKeyPressListener("Escape", { effect: onClose, isDisabled: isEscapeKeypressDisabled });

  useClickAwayListener(contentContainerRef, {
    isDisabled: isClickAwayDisabled,
    effect: onClose,
  });

  return (
    <div ref={ref}>
      {isMounted && isOpen
        ? createPortal(
            <FocusTrap>
              <FullscreenContainer role="dialog">
                <BackdropContainer>{backdrop}</BackdropContainer>
                <ContentContainer>
                  <div ref={contentContainerRef}>{children}</div>
                </ContentContainer>
              </FullscreenContainer>
            </FocusTrap>,
            document.body
          )
        : null}
    </div>
  );
});
