import { RefObject, useEffect } from "react";

interface Options {
  /** Attribute of an element that should be ignored. Example: 'data-preserve-scroll'. */
  skipAttribute?: string;
  /** Whether to disable the listener. */
  isDisabled?: boolean;
  /** The side effect to trigger when the user clicks away from the element. */
  effect?: () => void;
}

/**
 * TODO: Extract this to a separate package.
 *
 * Triggers a side effect when the user clicks away from the specified element,
 * i.e. outside the element's bounds.
 *
 * @param ref                     The element to listen for clicks on. Example: 'ref.current'.
 * @param params. skipAttribute   Attribute of an element that should be ignored. Example: 'data-preserve-scroll'.
 * @param params.isDisabled       Whether to disable the listener.
 * @param params.effect           The side effect to trigger when the user clicks away from the element.
 */
export const useClickAwayListener = <T extends EventTarget>(ref: RefObject<T>, params: Options) => {
  const { skipAttribute, isDisabled, effect } = params;

  useEffect(() => {
    if (isDisabled) {
      return;
    }

    const listener = (event: Event) => {
      if (!ref.current) {
        return;
      }

      let targetEl = event.target;

      do {
        if (targetEl === ref.current || (targetEl as Element).hasAttribute?.(skipAttribute ?? "")) {
          return;
        }
        targetEl = (targetEl as Element)?.parentNode;
      } while (targetEl);

      effect?.();
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [effect, skipAttribute, isDisabled, ref]);
};
