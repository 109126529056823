import { useEffect } from "react";

function onMove(e: Event) {
  const { target } = e;

  if ((target as Element).hasAttribute("data-preserve-scroll")) {
    return;
  }

  e.preventDefault();
}

function disableBodyScroll() {
  const sw = window.innerWidth - document.documentElement.clientWidth;
  document.documentElement.style.setProperty("height", `${window.innerHeight - 1}px`);
  document.body.style.setProperty("overflow", "hidden");

  if (sw > 0) {
    document.body.style.setProperty("padding-right", `${sw}px`);
  }

  window.addEventListener("pointermove", onMove);
  window.addEventListener("mousemove", onMove);
  window.addEventListener("touchmove", onMove);
}

function enableBodyScroll() {
  document.documentElement.style.removeProperty("height");
  document.body.style.removeProperty("overflow");
  document.body.style.removeProperty("padding-right");

  window.removeEventListener("pointermove", onMove);
  window.removeEventListener("mousemove", onMove);
  window.removeEventListener("touchmove", onMove);
}

type Params = {
  isDisabled?: boolean;
};

/**
 * TODO: Extract this to a separate package.
 *
 * Side effect that disables scroll on the document's body.
 * Allows for inner scrolls (e.g. modals) to be preserved.
 *
 * @param params.isEnabled    Whether to enable or disable the scroll lock.
 */
export function useBodyScrollLockEffect(params: Params) {
  const { isDisabled } = params;

  useEffect(() => {
    if (isDisabled) {
      enableBodyScroll();
    } else {
      disableBodyScroll();
    }
  }, [isDisabled]);
}
