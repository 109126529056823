import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const SceneWrapper = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const CommonButton = styled.button`
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background: #f5f5f5;
  }
`;

const SceneTitle = styled.h1`
  font-size: 2rem;
  font-weight: bold;
`;

const SceneDescription = styled.p`
  font-weight: lighter;
  line-height: 1.4;
`;

type StorySceneContentProps = PropsWithChildren<{
  title: string;
  description: string;
  onOpen: () => void;
}>;

export function StoryScene(props: StorySceneContentProps) {
  const { children, title, description, onOpen } = props;

  return (
    <SceneWrapper>
      <SceneTitle>{title}</SceneTitle>
      <SceneDescription>{description}</SceneDescription>
      <CommonButton onClick={onOpen}>Open Modal</CommonButton>
      <hr />
      {children}
    </SceneWrapper>
  );
}

export const ModalWrapper = styled.div`
  border-radius: 8px;
  height: 450px;
  width: 320px;
  background-color: white;
  padding: 40px;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  gap: 30px;
  border: 1px solid;
  border-color: #ccc;
  overflow-y: auto;
`;

export const ModalBackdrop = styled.div`
  height: 100%;
  width: 100%;
  background: rgba(110, 110, 110, 0.2); // Make sure this color has an opacity of less than 1
  backdrop-filter: blur(8px); // This be the blur
`;

export const ModalCloseButton = styled.button`
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border: 1px solid;
  border-radius: 50%;
  border-color: #e5e7e8;
  background-color: transparent;
  font-size: 0.8em;

  cursor: pointer;
  &:before {
    content: "x";
    font-weight: 300;
    margin-bottom: 4px;
    margin-left: 1px;
  }
`;

export const ModalActionsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ModalTitle = styled.h1`
  font-size: 1.7em;
  margin: 0;
`;

export const ModalTextContent = styled.p`
  font-size: 0.8em;
  font-weight: lighter;
  margin: 0;
  line-height: 1.7;
`;

type ModalDialogProps = PropsWithChildren<{
  onClose: () => void;
}>;

export function ModalDialog(props: ModalDialogProps) {
  const { children, onClose } = props;

  return (
    <ModalWrapper>
      <ModalActionsRow>
        <ModalCloseButton onClick={onClose} />
      </ModalActionsRow>
      <ModalTitle>Modal Title</ModalTitle>
      <ModalTextContent>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
        dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
        clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
        consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
        diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
        takimata sanctus est Lorem ipsum dolor sit amet.
      </ModalTextContent>
      {children}
    </ModalWrapper>
  );
}
