import type { DefaultTheme, ThemedStyledProps, CSSObject } from "styled-components";

const DEFAULT_SCREEN_KEY = "initial";

type Screens = DefaultTheme["screens"];
type ScreenKeys = keyof Screens | typeof DEFAULT_SCREEN_KEY;

type ResponsifyProp<Prop> = Prop | Partial<Record<ScreenKeys, Prop>>;
type UnresponsifyProp<Prop> = Prop extends Partial<Record<ScreenKeys, unknown>> ? never : Prop;

export type ResponsifyProps<Props> = {
  [Key in keyof Props]: ResponsifyProp<Props[Key]>;
};

export type UnresponsifyProps<Props> = {
  [Key in keyof Props]: UnresponsifyProp<Props[Key]>;
};

const isObject = (obj: unknown): obj is Record<string, unknown> => {
  return Object.prototype.toString.call(obj) === "[object Object]";
};

export const createMinWidthMediaQuery = (value: number | string) => {
  const normalizedValue = typeof value === "number" ? `${value}px` : value;
  return `@media only screen and (min-width: ${normalizedValue})`;
};

export const mapObjectValues = <Props, NewValue>(fn: (value: Props[keyof Props]) => NewValue, obj: Props) => {
  const newObj = {} as Record<keyof Props, NewValue>;

  for (const key in obj) {
    newObj[key] = fn(obj[key]);
  }

  return newObj;
};

export const groupPropsByKey = <Props>(groupBy: string, props: Props, handleNoObject = true) => {
  const groupedProps = {} as Record<keyof Props, unknown>;

  for (const key in props) {
    const value = props[key];

    if (isObject(value)) {
      groupedProps[key] = value[groupBy];
    } else if (handleNoObject) {
      groupedProps[key] = value;
    }
  }

  return groupedProps;
};

export const groupPropsByMediaqueries = <Props, MediaQueries extends Partial<Record<keyof Screens, string>>>(
  props: Props,
  mediaQueries: MediaQueries
) => {
  const result = {} as Record<string, Record<keyof Props, any>>;

  /*
    Collect initial styles under the DEFAULT_SCREEN_KEY key
  */
  result[DEFAULT_SCREEN_KEY] = groupPropsByKey(DEFAULT_SCREEN_KEY, props);

  /*
    Extract valid theme screens keys from props:
    1. Keep keys only from values of type object, since other values are already collected as initial values
    2. Remove duplicates from the array
    3. Filter array leaving only valid mediaqueries keys
  */
  const objectValues = Object.values(props).filter(isObject);
  const objectValuesKeys = objectValues.map(Object.keys).flat();

  const uniqueObjectValuesKeys = [...new Set(objectValuesKeys)];

  const filteredScreens = uniqueObjectValuesKeys.filter(
    (key): key is keyof Screens => key in mediaQueries && key !== DEFAULT_SCREEN_KEY
  );

  /*
   Collect mediaqueries styles
  */
  filteredScreens.forEach((key) => {
    const mediaQuerySelector = mediaQueries[key];
    if (mediaQuerySelector) {
      result[mediaQuerySelector] = groupPropsByKey(key, props, false);
    }
  });

  return result;
};

export const stylesGenerator = <Props>(generator: (p: ThemedStyledProps<Props, DefaultTheme>) => CSSObject) => {
  return (props: ThemedStyledProps<ResponsifyProps<Props>, DefaultTheme>): CSSObject => {
    const { theme, ...styledProps } = props;
    const mediaqueries = mapObjectValues(createMinWidthMediaQuery, theme.screens);
    const propsWithMediaqueries = groupPropsByMediaqueries(styledProps, mediaqueries);
    const styles = mapObjectValues((props) => generator({ ...props, theme } as any), propsWithMediaqueries);
    const { [DEFAULT_SCREEN_KEY]: baseStyles, ...responsiveStyles } = styles;

    return {
      ...baseStyles,
      ...responsiveStyles,
    };
  };
};
